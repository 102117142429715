import React, { useContext } from "react"
import { ModalContext } from "../../contexts/modalContext"

const FinalCta = props => {
  const { handleModal } = useContext(ModalContext)
  return (
    <section id="final-cta" className="mh-section">
      <div className="grid-container">
        <div className="final-content">
          <h2>
            <span className="tape">
              DÉVELOPPER VOTRE ORGANISATION{" "}
              <span className="highlighted">pour l'avenir</span>.
            </span>
          </h2>

          <div className="final-copy">
            <p>
              Commandez <em>Être un leader visionnaire</em> et obtenez plus des
              ressources gratuites.{" "}
            </p>

            <p>
              <button className="button" onClick={() => handleModal()}>
                <i className="fa fa-shopping-cart" /> Commander
              </button>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FinalCta
