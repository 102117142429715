import React from "react"

const LearnColumn = () => {
  return (
    <section id="learn-columns" className="mh-section">
      <div className="grid-container">
        <h2>
          <span className="highlighted">La vision</span> est essentielle pour...
        </h2>

        <div className="col">
          <div className="icon">
            <i className="fa fa-eye" />
          </div>

          <h3>Optimiser vos efforts</h3>

          <p>
            Lorsque vous avez un énoncé de vision solide, vous savez clairement
            où vous allez. Cette démarche agit comme un filtre pour cibler votre
            stratégie. Sans ce document, les leaders prennent seulement des
            décisions à court terme et ne font que saisir les opportunités du
            moment. Protégez le temps et les efforts de votre équipe grâce à une
            vision claire et pertinente.
          </p>
        </div>

        <div className="col">
          <div className="icon">
            <i className="fa fa-lightbulb" />
          </div>

          <h3>DYNAMISER VOTRE ÉQUIPE </h3>

          <p>
            Nos équipes ont besoin d’une vision qui leur demande le meilleur,
            les pousse à innover et inspire leur créativité. Avec un énoncé de
            vision, vous dynamiserez votre équipe à relever le défi et à
            accomplir plus que vous ne l'auriez imaginé.
          </p>
        </div>

        <div className="col">
          <div className="icon">
            <i className="fa fa-chart-line" />
          </div>

          <h3>DÉVELOPPER VOTRE ENTREPRISE </h3>

          <p>
            Les organisations préparées pour l'avenir sont mises en place pour
            saisir les opportunités, éviter les erreurs coûteuses et créer des
            percées sur leur marché. Grâce à un énoncé de vision qui définit la
            direction à prendre, non seulement vous accélérerez la croissance de
            votre entreprise, mais vous y parviendrez plus rapidement.
          </p>
        </div>
      </div>
    </section>
  )
}

export default LearnColumn
