import React, { useContext } from "react"
import { ModalContext } from "../../contexts/modalContext"

const Testimonials = props => {
  const { handleModal } = useContext(ModalContext)

  return (
    <section id="testimonials" className="mh-section">
      <h2>
        <span className="tape">
          <span className="highlighted">Recommandations</span> des précédents
          ouvrages de Michael Hyatt
        </span>
      </h2>

      <div className="grid-container testimonials masonry">
        <div className="testimonial masonry-brick">
          <div className="testimonial-content">
            <div className="photo">
              <img
                src="https://visiondrivenleader.com/wp-content/themes/mh-vision/img/testimonials/tony.png"
                width="200"
                height="200"
                alt={"Tony Robbins"}
              />{" "}
            </div>

            <p>
              «Nous devons prendre des mesures énergiques pour que nos rêves
              deviennent réalité. L'une des raisons pour lesquelles nous sommes
              si peu nombreux à réaliser ce que nous voulons vraiment, c'est que
              nous ne nous concentrons jamais sur notre objectif, nous ne nous
              concentrons jamais sur notre pouvoir. Personne ne comprend cela
              mieux que Michael Hyatt, et il a conçu une nouvelle approche
              facile à suivre pour exploiter ce pouvoir dans son nouveau livre
              <em>Enfin libéré du surmenage.</em> »
            </p>

            <div className="byline">
              <strong>TONY ROBBINS</strong>

              <span>
                Auteur à succès au palmarès du New York Times,{" "}
                <i>Unshakeable</i>
              </span>
            </div>
          </div>
        </div>

        <div className="testimonial masonry-brick">
          <div className="testimonial-content">
            <div className="photo">
              <img
                src="https://visiondrivenleader.com/wp-content/themes/mh-vision/img/testimonials/sally.jpg"
                width="200"
                height="200"
                alt={"Sally Hogshead"}
              />
            </div>

            <p>
              «Tout au long de votre vie, vous rencontrerez trois types de
              leaders. Le premier inspire l'ambition, sans résultats. Le second
              améliore les résultats, mais ignore l'esprit. Dans{" "}
              <em>Vivre enfin ma meilleure année</em>, Michael Hyatt prouve
              qu'il est le rare troisième type de leader - celui qui à la fois
              augmente nos performances et élève notre âme.»
            </p>

            <div className="byline">
              <strong>SALLY HOGSHEAD</strong>

              <span>
                Auteur à succès au palmarès du New York Times <em>Fascinate</em>
                , <em>How the World Sees You</em>, et{" "}
                <em>Radical Careering.</em>
              </span>
            </div>
          </div>
        </div>

        <div className="testimonial masonry-brick">
          <div className="testimonial-content">
            <div className="photo">
              <img
                src="https://visiondrivenleader.com/wp-content/themes/mh-vision/img/testimonials/john.jpg"
                width="200"
                height="200"
                alt={"John C. Maxwell"}
              />
            </div>

            <p>
              «Michael Hyatt est l'un des meilleurs leaders que je connaisse, et
              je suis ravi qu'il ait écrit <em>Enfin libéré du surmenage</em>.
              L'utilisation par Michael de tests et de recherches approfondis,
              ainsi que ses antécédents éprouvés en tant que dirigeant
              d'entreprises établies et en démarrage, se traduit par un livre
              plein d'idées et de mesures pratiques. Les dirigeants s'appuient
              sur des systèmes intelligents pour les aider à diriger au bureau
              comme à la maison, et <em>Enfin libéré du surmenage</em> fournit
              le type de système dont tout dirigeant intelligent a besoin.»
            </p>

            <div className="byline">
              <strong>JOHN C. MAXWELL</strong>

              <span>Auteur, conférencier et expert en leadership </span>
            </div>
          </div>
        </div>

        <div className="testimonial masonry-brick">
          <div className="testimonial-content">
            <div className="photo">
              <img
                src="https://visiondrivenleader.com/wp-content/themes/mh-vision/img/testimonials/amy.jpg"
                width="200"
                height="200"
                alt={"Amy Porterfield"}
              />
            </div>

            <p>
              «Dites adieu à #échecobjectif une fois que vous aurez appris le
              système de fixation d'objectifs de Michael Hyatt, le meilleur de
              l'histoire de l'année. Son enseignement s'appuie sur les
              meilleures connaissances scientifiques disponibles, et la preuve
              en est donnée par les récits concrets de gens ordinaires qui ont
              obtenu des résultats extraordinaires.»
            </p>

            <div className="byline">
              <strong>AMY PORTERFIELD</strong>

              <span>
                Hôte du podcast : <i>The Online Marketing Made Easy</i>
              </span>
            </div>
          </div>
        </div>

        <div className="testimonial masonry-brick">
          <div className="testimonial-content">
            <div className="photo">
              <img
                src="https://visiondrivenleader.com/wp-content/themes/mh-vision/img/testimonials/ruth.jpg"
                width="200"
                height="200"
                alt={"Ruth Soukup"}
              />
            </div>

            <p>
              «L'approche pratique de Michael Hyatt en matière de productivité
              n'est pas seulement un autre guide tactique rempli de bonnes idées
              - c'est une stratégie globale pour refondre votre vie. Il ne
              s'agit pas seulement d'en faire plus, mais de faire les bonnes
              choses - et cela commence par savoir où vous voulez aller.»
            </p>

            <div className="byline">
              <strong>RUTH SOUKUP</strong>

              <span>
                Auteur à succès au palmarès du New York Times{" "}
                <i>Do It Scared</i>
              </span>
            </div>
          </div>
        </div>

        <div className="testimonial masonry-brick">
          <div className="testimonial-content">
            <div className="photo">
              <img
                src="https://visiondrivenleader.com/wp-content/themes/mh-vision/img/testimonials/cal-newport.jpg"
                width="200"
                height="200"
                alt={"Cal Newport"}
              />
            </div>

            <p>
              «Michael Hyatt a écrit un traité intelligent, basé sur des preuves
              et souvent surprenant, sur la façon de fixer les bons objectifs et
              de les mener à bien. Une lecture incontournable pour quiconque
              cherche à prendre des mesures systématiques pour améliorer sa
              vie.»
            </p>

            <div className="byline">
              <strong>CAL NEWPORT</strong>

              <span>
                Auteur à succès au palmarès du New York Times, <i>Deep Work</i>{" "}
                and <i>Digital Minimalism</i>
              </span>
            </div>
          </div>
        </div>

        <div className="testimonial masonry-brick">
          <div className="testimonial-content">
            <div className="photo">
              <img
                src="https://visiondrivenleader.com/wp-content/themes/mh-vision/img/testimonials/chalene.jpg"
                width="200"
                height="200"
                alt={"Charlene Johnson"}
              />
            </div>

            <p>
              «Un plan entièrement personnalisable pour réaliser la conception
              et l'exécution de la vie que vous voulez vivre.»
            </p>

            <div className="byline">
              <strong>CHALENE JOHNSON</strong>

              <span>
                Auteur à succès au palmarès du New York Times ; PDG, Team
                Johnson
              </span>
            </div>
          </div>
        </div>

        <div className="testimonial masonry-brick">
          <div className="testimonial-content">
            <div className="photo">
              <img
                src="https://visiondrivenleader.com/wp-content/themes/mh-vision/img/testimonials/greg.jpg"
                width="200"
                height="200"
                alt={"Greg McKeown"}
              />
            </div>

            <p>
              «Vous pouvez soit poursuivre intentionnellement l'essentiel, soit
              dériver involontairement vers le non-essentiel. Ce livre enseigne
              brillamment comment faire le premier tout en évitant le second.
              Lisez-le et vivez-le, vous l'aimerez. »
            </p>

            <div className="byline">
              <strong>GREG MCKEOWN</strong>

              <span>
                Auteur à succès au palmarès du New York Times,{" "}
                <i>Essentialism</i>
              </span>
            </div>
          </div>
        </div>

        <div className="testimonial masonry-brick">
          <div className="testimonial-content">
            <div className="photo">
              <img
                src="https://visiondrivenleader.com/wp-content/themes/mh-vision/img/testimonials/max.jpg"
                width="200"
                height="200"
                alt={"Max Lucado"}
              />
            </div>
            <p>
              «Michael Hyatt et Daniel Harkavy ont beaucoup à nous apprendre.
              Dans un monde où les vies sont aléatoires et souvent sans
              direction, leurs vies se distinguent comme des exemples de
              carrières bien choisies, de temps bien utilisé et de passion
              profondément écoutée.»
            </p>

            <div className="byline">
              <strong>MAX LUCADO</strong>

              <span>
                Auteur à succès au palmarès du New York Times, <i>Fearless</i>{" "}
                et <i>Outlive Your Life</i>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="grid-container">
        <div className="grid-100">
          <p style={{ textAlign: "center" }}>
            <button className="button" onClick={() => handleModal()}>
              <i className="fa fa-shopping-cart" /> Commander
            </button>
          </p>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
