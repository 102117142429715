import React, { useContext } from "react"
import Img from "gatsby-image/index"
import { ModalContext } from "../../contexts/modalContext"

const Hero = ({ image }) => {
  const { handleModal } = useContext(ModalContext)

  return (
    <section id="hero" className="mh-section">
      <div className="grid-container">
        <div className="hero-cover">{image && <Img fluid={image} />}</div>

        <div className="hero-content">
          <h1>
            <span className="highlighted">La Vision</span> est plus importante
            que jamais
          </h1>

          <p className="subheading">
            Créez une vision claire et convaincante pour développer votre
            organisation. Le dernier livre de Michael Hyatt, auteur à succès au
            palmarès du New York Times, vous donnera toutes les ressources
            nécessaires pour atteindre cet objectif.{" "}
          </p>

          <p className="options">
            <button
              className="button"
              data-modal="buy"
              onClick={() => handleModal()}
            >
              <i className="far fa-calendar-check" /> Commander
            </button>

            <a href="#buy" className="button secondary-button">
              <i className="fa fa-gift" /> Recevoir les ressources
            </a>
          </p>
        </div>
      </div>
    </section>
  )
}

export default Hero
