import React, { useContext } from "react"
import Img from "gatsby-image/index"
import { ModalContext } from "../../contexts/modalContext"

const Learn = ({ image }) => {
  const { handleModal } = useContext(ModalContext)

  return (
    <section id="learn" className="mh-section">
      <div className="grid-container">
        <div className="photo">
          <Img fluid={image} />.
        </div>

        <div className="learn-content">
          <p>
            La plupart des leaders d'opinion vous recommanderont de créer une
            déclaration de vision que vous avez peut-être déjà en mains.
          </p>

          <p>
            Mais vous ne pouvez pas saisir toutes les possibilités de l'avenir
            dans une seule et même déclaration.{" "}
          </p>

          <p>
            Après avoir lu <em>Être un leader visionnaire</em>, vous disposerez
            de tous les outils nécessaires pour rédiger un énoncé de vision. Ce
            document indispensable sera votre colonne vertébrale pour partager
            avec efficacité et clarté votre vision au sein d'une organisation.{" "}
          </p>

          <p>
            En achetant votre livre, vous recevrez également l'outil gratuit{" "}
            <em>Le B.A.-BA d’une vision</em> qui vous aidera à rédiger
            facilement cet énoncé de vision.{" "}
          </p>

          <p>
            <button className="button" onClick={() => handleModal()}>
              <i className="fa fa-shopping-cart" /> Commander
            </button>
          </p>
        </div>
      </div>
    </section>
  )
}

export default Learn
