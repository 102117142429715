import React, { useRef } from "react"
import "./lightbox.scss"

const Lightbox = props => {
  const lightboxClass = props.isOpen ? " open" : " closed"
  const source = props.isOpen
    ? "https://player.vimeo.com/video/" + props.videoId + "?autoplay=1"
    : "https://player.vimeo.com/video/" + props.videoId

  const node = useRef()

  const checkOutside = e => {
    if (node.current.contains(e.target)) {
      return
    }
    props.closeLightbox()
  }

  const handleKeyPress = e => {
    if (e.which === 27) {
      // Close my modal window
      props.closeLightbox()
    }
  }

  return (
    <div
      className={"lightbox" + lightboxClass}
      onClick={checkOutside}
      onKeyPress={handleKeyPress}
      role={"button"}
      tabIndex={0}
      aria-label={"Close lightbox"}
    >
      <div ref={node} className="lightbox-content">
        <button
          className={"fermer-lightbox"}
          onClick={props.closeLightbox}
          style={{ top: "-5000px" }}
        >
          fermer
        </button>
        <iframe
          title={"video"}
          src={source}
          width="640"
          height="360"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      </div>
    </div>
  )
}

export default Lightbox
