import React, { useContext } from "react"
import { ModalContext } from "../../contexts/modalContext"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Buy = props => {
  const { handleModal } = useContext(ModalContext)
  const { handleBonusesModal } = useContext(ModalContext)

  const data = useStaticQuery(graphql`
    query {
      bonusImage: file(relativePath: { eq: "bonuses/postlaunch-1-9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <section id="buy" className="mh-section">
      <div className="grid-container">
        <h2>
          Recevoir des ressources gratuites lorsque vous achetez ce livre.
        </h2>

        <div className="col" style={{ margin: "0 auto", float: "none" }}>
          <div className="buy-container">
            <Img
              className="bonus-image"
              style={{ margin: "0 auto" }}
              fluid={data.bonusImage.childImageSharp.fluid}
            />

            <ul>
              <li>
                Guide pour partager votre vision : Comment faire participer
                votre équipe à votre vision (vidéo et guide)
              </li>
              <li>
                Kit Vision 20/20 : un guide avec des citations, des conseils et
                des idées pour vous aider à avoir une vision claire de ce qui
                est possible
              </li>
              <li>
                Le B.A.-BA d’une vision : rédigez votre énoncé de vision en
                quelques minutes grâce aux vidéos de coaching de Michael Hyatt
              </li>
            </ul>

            <div className="options">
              <p>Étape 1:</p>

              <p>
                <button className="button" onClick={() => handleModal()}>
                  <i className="fa fa-shopping-cart" />
                  &ensp;Commander maintenant
                </button>
              </p>

              <p>Étape 2:</p>

              <p>
                <button className="button" onClick={() => handleBonusesModal()}>
                  <i className="fa fa-gift" />
                  &ensp;Recevoir les ressources gratuites
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

Buy.propTypes = {}

export default Buy
