import React, { useState } from "react"
import Lightbox from "./lightbox"
import pdf from "../../pdf/Extrait_EtreUnLeaderVisionnaire_ch1.pdf"

const Intro = props => {
  const [isOpen, setOpen] = useState(false)

  function handleKeyUp(e) {
    const keys = {
      27: () => {
        e.preventDefault()
        closeLightbox()
        window.removeEventListener("keyup", handleKeyUp, false)
      },
    }

    if (keys[e.keyCode]) {
      keys[e.keyCode]()
    }
  }

  function showVideo() {
    window.addEventListener("keyup", handleKeyUp, false)
    setOpen(true)
  }

  function closeLightbox() {
    setOpen(false)
  }

  return (
    <section id="intro" className="mh-section">
      <div className="grid-container">
        <div className="intro-content">
          <div className="intro-content-container">
            <h2>
              Comment vous projetez-vous{" "}
              <span className="highlighted">dans l'avenir ?</span> avec crainte
              ou excitation?
            </h2>

            <p>
              Il n’est pas toujours facile de réfléchir à l’avenir de votre
              organisation avec recul. Celui-ci peut vous sembler trop immense
              et menaçant.
            </p>

            <p>
              Comment vous y préparer ? Quand trouver le temps ? À quoi
              ressemblera l'avenir ?{" "}
            </p>

            <p>
              L'avenir de votre entreprise ou organisation ne doit pas vous
              faire peur. Une vision claire et convaincante est la clé pour la
              mener vers un avenir meilleur et passionnant.
            </p>

            <p>
              <em>Être un leader visionnaire</em> vous aidera à développer votre
              organisation en utilisant une vision simple, attrayante,
              inspirante et pratique de l'avenir.{" "}
            </p>

            <p className="button-container">
              <button
                className="button activate-video js-modal-btn"
                data-video-id="449814443"
                onClick={() => showVideo()}
              >
                <i className="fa fa-play"></i>&ensp;Voir la video
              </button>
              &ensp;
              <a className="button read-chapter" href={pdf}>
                <i className="fa fa-download"></i>&ensp;Lire un extrait
              </a>
            </p>
          </div>
        </div>
      </div>
      {isOpen && (
        <Lightbox
          isOpen={isOpen}
          videoId={449814443}
          closeLightbox={closeLightbox}
        />
      )}
    </section>
  )
}

Intro.propTypes = {}

export default Intro
