import React from "react"

const IntroSplit = () => {
  return (
    <section id="intro-split" className="mh-section">
      <div className="grid-container">
        <div className="left-box">
          <p>
            <i className="far fa-times-circle"></i>
          </p>

          <h3>VOUS N’AVEZ PAS BESOIN D’UNE DÉCLARATION DE VISION</h3>
        </div>

        <div className="right-box">
          <p>
            <i className="far fa-check-circle"></i>
          </p>

          <h3>VOUS AVEZ BESOIN DE RÉDIGER L’ÉNONCÉ DE VOTRE VISION</h3>

          <p>
            Passer gratuitement l'
            <a
              href="https://evaluations.motiveparlessentiel.org/vision/"
              target="_blank"
              rel={"noreferrer noopener"}
              aria-label={"Évaluation de la vision"}
            >
              <strong>
                <em>Évaluation de la vision</em>
              </strong>
            </a>{" "}
            pour déterminer si votre vision actuelle a tous les leviers pour
            vous accompagner au mieux votre organisation.{" "}
          </p>
        </div>
      </div>
    </section>
  )
}

export default IntroSplit
