import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Accordion from "../components/accordion"
import Intro from "../components/home/intro"
import Hero from "../components/home/hero"
import IntroSplit from "../components/home/intro-split"
import Learn from "../components/home/learn"
import LearnColumn from "../components/home/learn-column"
import About from "../components/home/about"
import Testimonials from "../components/home/testimonials"
import Buy from "../components/home/buy"
import Church from "../components/home/church"
import FinalCta from "../components/home/final-cta"

const Masonry = typeof window !== `undefined` ? require("masonry-layout") : null
const IndexPage = props => {
  const data = props.data

  useEffect(() => {
    const elem = document.querySelector(".masonry")
    new Masonry(elem, {
      itemSelector: ".masonry-brick",
      columnWidth: 300,
    })
  }, [])

  return (
    <Layout>
      <SEO title="Être un leader visionnaire" />
      <div id="content" className="site-content">
        <div id="primary" className="content-area">
          <main id="main" className="site-main">
            <article
              id="post-63"
              className="post-63 page type-page status-publish hentry"
            >
              <Hero
                image={data.etreUnLeaderVisionnaireCover.childImageSharp.fluid}
              />

              <section id="featured-in-1" className="mh-section featured-in" />

              <Intro />

              <IntroSplit />

              <Learn image={data.michealArrows.childImageSharp.fluid} />

              <LearnColumn />

              <About />

              <section id="featured-in-2" className="mh-section featured-in" />

              <Testimonials />

              <Buy />

              <section id="vision" className="mh-section">
                <div className="grid-container">
                  <div className="vision-content">
                    <p className="topheading">
                      <span className="tape">
                        Recevez en plus un outil gratuit à l'achat d'un livre&nbsp;:
                      </span>
                    </p>

                    <h2>
                      <span className="tape">
                        Le B.A.-BA d'une <span className="highlighted">Vision</span>
                      </span>
                    </h2>

                    <p className="copy">
                      Avec l'achat de votre livre, vous aurez également un accès
                      gratuit à l’outil <em>Le B.A.-BA d’une vision</em>. Cet outil
                      unique vous aidera à rédiger votre énoncé de vision en
                      quelques minutes grâce aux vidéos de coaching de Michael
                      Hyatt. En commandant <em>Être un leader visionnaire</em>, vous
                      disposerez de tous les outils nécessaires pour créer un
                      énoncé de vision clair et convaincant que vous pourrez
                      utiliser dans votre entreprise.
                    </p>
                  </div>
                </div>
              </section>

              <Church />

              <section id="faq" className="mh-section">
                <div className="grid-container">
                  <h2>
                    Foire aux <span className="highlighted">questions</span>
                  </h2>
                  <Accordion
                    title={
                      "Je n’habite pas en France métropolitaine ni en Suisse, ai-je également droit aux ressources gratuites ?"
                    }
                    content={
                      "Oui ! Nous avons le plaisir d'accepter et valider des achats internationaux. Les ressources gratuites sont livrées sous forme digitale via votre adresse électronique, ainsi il n'y a aucune expédition internationale facturée."
                    }
                  />
                  <Accordion
                    title={
                      "Puis-je acheter le livre chez n’importe quel libraire pour obtenir les ressources gratuites ?"
                    }
                    content={
                      "Absolument. Vous pouvez acheter le livre chez n’importe quel libraire. Il vous suffira de récupérer sur la page 3 du livre le numéro vous donnant accès à ces ressources gratuites sur ce site en cliquant ici."
                    }
                  />
                  <Accordion
                    title={
                      "Puis-je recevoir les ressources gratuites si j'achète le livre électronique ?"
                    }
                    content={
                      "Absolument. Les conditions sont les mêmes que pour le livre papier. Il vous suffira de récupérer sur la page 3 du livre le numéro vous donnant accès aux ressources sur ce site en cliquant ici."
                    }
                  />
                  <Accordion
                    title={
                      "Comment recevoir mes ressources gratuites une fois que j’ai commandé mon livre ?"
                    }
                    content={
                      "Lorsque vous avez commandé votre livre, il vous faut attendre la réception physique de votre ouvrage pour accéder à la page 3 dans laquelle un numéro vous est attribué pour recevoir vos ressources gratuites Il vous suffira ensuite de cliquer ici pour suivre la procédure."
                    }
                  />
                  <Accordion
                    title={
                      "Dans quels délais vais-je recevoir mes ressources gratuites et sous quelle forme ?"
                    }
                    content={
                      "Elles vous seront livrées au plus tard 1 semaine après avoir effectué la procédure sur le site. Vous les recevrez via voie électronique aussi pensez à bien renseigner votre email. Rendez-vous ici pour recevoir vos ressources gratuites."
                    }
                  />
                </div>
              </section>
              <FinalCta />
            </article>
          </main>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    etreUnLeaderVisionnaireCover: file(relativePath: { eq: "etre-un-leader-visionnaire-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 570) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    michealArrows: file(relativePath: { eq: "michael-arrows.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
