import React from "react"
import Img from "gatsby-image/index"
import { graphql, useStaticQuery, Link } from "gatsby"

const Church = props => {
  const data = useStaticQuery(graphql`
    query {
      vdlChurchIpad2: file(
        relativePath: { eq: "bonuses/vdl-church-ipad2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <section id="church" className="mh-section">
      <div className="grid-container bonus-1">
        <div className="bonus-screenshot">
          <Img fluid={data.vdlChurchIpad2.childImageSharp.fluid} />
        </div>

        <div className="bonus-content">
          <h2>POUR LES RESPONSABLES D’ÉGLISES NÉCESSITANT UNE VISION </h2>

          <h3>
            UNE RESSOURCE GRATUITE MISE À DISPOSITION:{" "}
            <span className="highlighted">
              ÊTRE UN LEADER VISIONNAIRE POUR L’ÉGLISE
            </span>
            .
          </h3>

          <p>
            La vision est essentielle pour diriger votre ministère aujourd'hui.
            Mais à quoi ressemble ce processus pour créer et communiquer une
            vision pour votre église ? Dans ce guide complémentaire, Thom
            Rainer, ancien PDG de <em>LifeWay</em> et actuel PDG de{" "}
            <em>Church Answers</em>, et son fils, Art Rainer, vice-président du{" "}
            <em>Southeastern Baptist Theological Seminary</em>, démystifient la
            vision pour votre église. Découvrez comment diriger votre ministère
            avec vision à l’aide de la ressource{" "}
            <em>Être un leader visionnaire pour l’église.</em>
          </p>

          <p>
            <Link className="button" to="/eglise" target="_blank">
              <i className="fa fa-download" />
              Télécharger maintenant
            </Link>
          </p>
        </div>
      </div>
    </section>
  )
}

export default Church
