import React, { useState } from "react"
import { motion } from "framer-motion"

const Accordion = props => {
  const [active, setActive] = useState(false)

  const variants = {
    open: {
      marginTop: "1rem",
      height: "auto",
      opacity: 1,
    },
    closed: {
      marginTop: "0rem",
      height: 0,
      opacity: 0,
      overflow: "hidden",
    },
  }

  const answerClasses = active ? "faq-answer active" : "faq-answer"

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      setActive(!active)
    }
  }

  return (
    <div className="faq">
      <h3
        role={"presentation"}
        onClick={() => setActive(!active)}
        onKeyDown={handleKeyDown}
      >
        {props.title}
      </h3>
      <motion.div
        initial={"closed"}
        animate={active ? "open" : "closed"}
        variants={variants}
        className={answerClasses}
        dangerouslySetInnerHTML={{ __html: props.content }}
      />
    </div>
  )
}

Accordion.propTypes = {}

export default Accordion
