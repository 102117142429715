import React, { useContext } from "react"
import { ModalContext } from "../../contexts/modalContext"

const About = props => {
  const { handleModal } = useContext(ModalContext)

  return (
    <section id="about" className="mh-section">
      <div className="grid-container">
        <div className="about-photo" />

        <div className="about-content">
          <h2>
            PAR L’EXPERT EN LEADERSHIP,{" "}
            <span className="highlighted">Michael Hyatt</span>
          </h2>

          <p>
            Michael Hyatt est le fondateur et le PDG de Michael Hyatt &amp; Co.,
            une entreprise de développement du leadership qui accompagne les
            leaders débordés, à réussir, à se concentrer pour optimiser leur
            travail et à s’épanouir personnellement et professionnellement.{" "}
          </p>

          <p>
            Dans sa première entreprise, Michael a ressenti la douleur de ne pas
            avoir une vision claire. Son partenaire commercial et lui n'étaient
            pas préparés pour l’avenir et une série de faux pas stratégiques ont
            mis fin à leur activité.{" "}
          </p>

          <p>
            Mais lorsqu'il a pris la direction de Thomas Nelson Publishers,
            Michael a redressé sa division mourante et l'a conduite à devenir la
            division la plus rentable dans l'une des pires économies du pays.{" "}
          </p>

          <p>
            En tant qu'ancien PDG de Thomas Nelson Publishers, et aujourd'hui
            PDG d'une société de développement du leadership en pleine
            croissance, Michael connaît l'importance de la vision. Dans{" "}
            <em>Être un leader visionnaire</em>, il dévoile toutes les clés
            d’une vision pour propulser votre entreprise ou organisation vers un
            avenir meilleur.{" "}
          </p>

          <p>
            <button className="button" onClick={() => handleModal()}>
              <i className="fa fa-shopping-cart" /> Commander
            </button>
          </p>
        </div>
      </div>
    </section>
  )
}

About.propTypes = {}

export default About
